import React, { useContext, useEffect } from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltDots, faCommentAltLines, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import styles from "./ArticleToolbar.module.scss"
import { useSelector, useDispatch } from "react-redux"
import { selectArticleMarkedMessagesCount, selectCurrentArticle } from "../../store/knowledgeBase/selectors"
import { RESULTS_PER_PAGE } from "../../utility/knowledgeBase/sampleQuestions"
import { setOperatorQuestionsCount } from "../../store/knowledgeBase/thunks"
import { testId } from "../../utility/tests/testId"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"

const tNamespace = "knowledgeBase"

interface ArticleToolbarProps {
    isInWorkplace?: boolean
    answersCount?: number
    questionsCount?: number
    onOpenAnswers?: () => void
    onOpenQuestions?: () => void
    previousCount?: number
}

interface ToolbarItemProps {
    icon: IconDefinition
    itemsCount: number
    titleTranslation: string
    onClick?: () => void
    previousCount?: number
}

const ToolbarItem: React.FC<ToolbarItemProps> = props => {
    const { icon, itemsCount, titleTranslation, onClick, previousCount } = props
    const { t } = useTranslation()

    return (
        <Button variant="link" className={styles.articleToolbar__item} onClick={onClick}>
            <FontAwesomeIcon icon={icon} size="1x" className={styles.articleToolbar__icon} />
            {`${t(`${tNamespace}:${titleTranslation}`)}`}
            {previousCount ? (
                <>
                    <span className={styles.articleToolbar__number}>{`(${previousCount})`}</span>
                    {itemsCount}
                </>
            ) : (
                <>{` (${itemsCount})`}</>
            )}
        </Button>
    )
}

const ArticleToolbar: React.FC<ArticleToolbarProps> = props => {
    const { answersCount = 0, questionsCount = 0, isInWorkplace, previousCount, onOpenAnswers, onOpenQuestions } = props
    const dispatch = useDispatch()
    const currentArticle = useSelector(selectCurrentArticle)
    const markedMessagesCount = useSelector(selectArticleMarkedMessagesCount)
    const { shareId } = useContext(KnowledgeBaseContext)
    const questionsSum = questionsCount + markedMessagesCount

    const showToolbarItem = (count: number) => {
        return isInWorkplace ? count > 1 : true
    }

    useEffect(() => {
        const symbolCode = currentArticle?.Article?.SymbolCode ?? ""
        !shareId && setOperatorQuestionsCount(symbolCode, RESULTS_PER_PAGE, dispatch)
    }, [shareId])

    return (
        <div className={styles.articleToolbar} data-testid={testId.articleToolbar}>
            {onOpenAnswers && showToolbarItem(answersCount) && (
                <ToolbarItem
                    icon={faCommentAltDots}
                    itemsCount={answersCount}
                    titleTranslation="answer-options"
                    onClick={onOpenAnswers}
                />
            )}
            {onOpenQuestions && showToolbarItem(questionsSum) && (
                <ToolbarItem
                    icon={faCommentAltLines}
                    itemsCount={questionsSum}
                    titleTranslation="question-samples"
                    onClick={onOpenQuestions}
                    previousCount={previousCount}
                />
            )}
        </div>
    )
}

export default ArticleToolbar
