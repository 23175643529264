import { faCalendar } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import format from "date-fns/format"
import { useField } from "formik"
import { useRef } from "react"
import { Form, FormControl } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useModal } from "../../utility/common/useModal"
import { testId } from "../../utility/tests/testId"
import CalendarModal, { PickerProps } from "../CalendarModal/CalendarModal"
import CloseButton from "../CloseButton/CloseButton"
import styles from "./DatePickerInput.module.scss"
import { formatDateInput } from "./helpers"
import { DatePickerInputErrorType, useDatePickerErrorHandler } from "./hooks/useDatePickerErrorHandler"

const tNamespace = "date:date-input."

export interface DatePickerInputProps extends PickerProps {
    disabled?: boolean
    name: string
    error?: string
    label?: string
    value?: string
    onClear: () => void
    onChange: (dates: Date[]) => void
    manualEditing?: boolean
}

export const DatePickerInput = (props: DatePickerInputProps) => {
    const {
        value = null,
        label,
        name,
        onChange,
        onClear,
        selectsRange = false,
        showTimeInput = false,
        manualEditing = false,
        minDate,
        maxDate,
        disabled
    } = props

    const { t, i18n } = useTranslation()
    const [field, meta, { setValue, setError }] = useField<string>(name)
    const inputRef = useRef<HTMLInputElement>(null)
    const dropError = useDatePickerErrorHandler(setError)

    const validateDates = (startDate: Date, endDate: Date | null) => {
        if (isNaN(startDate.getTime()) || (endDate && isNaN(endDate.getTime()))) {
            dropError(DatePickerInputErrorType.format)
        } else if ((endDate && startDate <= endDate) || !endDate) {
            if (
                ((minDate && startDate > minDate) || !minDate) &&
                ((maxDate && (endDate || startDate) < maxDate) || !maxDate)
            ) {
                const result = [startDate]
                if (endDate) {
                    result.push(endDate)
                }
                onChange(result)
            } else {
                dropError(undefined, minDate, maxDate)
            }
        } else {
            dropError(DatePickerInputErrorType.invert)
        }
    }

    const handleCalendarSubmit = (dates: Date[]) => {
        if (dates[1]) {
            setValue(`${format(dates[0], "dd.MM.yyyy")}-${format(dates[1], "dd.MM.yyyy")}`)
        } else {
            setValue(format(dates[0], "dd.MM.yyyy"))
        }
        validateDates(dates[0], dates[1])
    }

    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <CalendarModal
            show={modalOpen}
            onClose={closeModal}
            onSubmit={handleCalendarSubmit}
            onExited={onExited}
            minDate={minDate}
            maxDate={maxDate}
            selectsRange={selectsRange}
            showTimeInput={showTimeInput}
            adjustDateOnChange
        />
    ))

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!manualEditing) {
            return
        }

        let input = e.target.value.replace(/\D/g, "")

        if (
            field.value &&
            (field.value.endsWith(".") || field.value.endsWith("-")) &&
            e.nativeEvent instanceof InputEvent &&
            e.nativeEvent.inputType === "deleteContentBackward"
        ) {
            input = input.slice(0, -1)
        }

        const formattedValue = formatDateInput(input, selectsRange)

        if ((selectsRange && formattedValue.length === 21) || (!selectsRange && formattedValue.length === 10)) {
            const [start, end] = formattedValue.split("-")
            const startDate = new Date(start.split(".").reverse().join("."))
            const endDate = end ? new Date(end.split(".").reverse().join(".")) : null

            validateDates(startDate, endDate)
        } else {
            dropError(DatePickerInputErrorType.incomplete)
        }

        if (formattedValue.length === 0) {
            onClear()
        }

        setValue(formattedValue)
        setTimeout(() => {
            if (inputRef.current) {
                const cursorPosition = formattedValue.length
                inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
            }
        }, 0)
    }

    const handleFocus = () => {
        setTimeout(() => {
            if (inputRef.current) {
                const cursorPosition = inputRef.current.value.length
                inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
            }
        }, 0)
    }

    const handleClear = () => {
        setValue("")
        onClear()
    }

    let error = ""
    if (typeof meta.error === "string") {
        const [namespace, key] = meta.error.split(":")
        const isTranslatable = key ? i18n.exists(key, { ns: namespace }) : false
        error = isTranslatable ? t(meta.error) : meta.error
    }

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <div className={cn(styles.picker, disabled && styles.picker_disabled)}>
                <FontAwesomeIcon
                    icon={faCalendar}
                    className={styles.picker__icon}
                    onClick={disabled ? undefined : openModal}
                    data-testid={testId.datePickerIcon}
                />
                <FormControl
                    name={name}
                    ref={inputRef}
                    value={value === null ? field.value : value}
                    disabled={disabled}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onClick={manualEditing ? handleFocus : openModal}
                    className={styles.picker__input}
                    isInvalid={!!meta.error}
                    data-testid={testId.datePickerInput}
                    placeholder={
                        manualEditing ? t(`${tNamespace}placeholder-${selectsRange ? "range" : "one"}`) : undefined
                    }
                />
                {!disabled && (
                    <CloseButton
                        onClick={handleClear}
                        className={cn(styles.picker__close, !!meta.error && styles.picker__close_error)}
                    />
                )}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </div>
        </>
    )
}
