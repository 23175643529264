import { Article, ArticleQuestion, MarkedMessagesReserve, SampleQuestionType } from "../../models/article"
import { Dispatch } from "../../utility/common/storeHelper"
import { actions, CurrentArticleState } from "./slice"
import { ArticleCompareMode } from "../../models/articleCompare"

export const revertArticleChanges = (article: Article, dispatch: Dispatch, currentAnswerId?: string) => {
    dispatch(actions.updateArticleAnswersForm({ Answers: article.Answers ?? [] }))
    currentAnswerId && dispatch(actions.updateCurrentAnswerById(currentAnswerId))
    dispatch(actions.updateArticleFormQuestions(article.Questions ?? []))
    dispatch(actions.restoreArticleMarkedQuestions())
}

export const handleAddArticleFormQuestions = (questions: string[], dispatch: Dispatch) =>
    dispatch(actions.addArticleFormQuestions(questions))

export const handleUpdateArticleFormQuestions = (questions: ArticleQuestion[], dispatch: Dispatch) =>
    dispatch(actions.updateArticleFormQuestions(questions))

export const handleRemoveArticleFormQuestions = (questionIds: string[], dispatch: Dispatch) =>
    dispatch(actions.removeArticleFormQuestions(questionIds))

export const handleAddArticleMarkedQuestions = (questions: SampleQuestionType[], dispatch: Dispatch) =>
    dispatch(actions.addArticleMarkedQuestions(questions))

export const handleRemoveArticleMarkedQuestions = (questionIds: string[], dispatch: Dispatch) =>
    dispatch(actions.removeArticleMarkedQuestions(questionIds))

export const handleSetArticleMarkedQuestionsCount = (count: number, dispatch: Dispatch) =>
    dispatch(actions.setArticleMarkedQuestionsCount(count))

export const handleReduceArticleMarkedQuestionsCount = (count: number, dispatch: Dispatch) =>
    dispatch(actions.reduceArticleMarkedQuestionsCount(count))

export const handleSetArticleMarkedQuestionsReserve = (data: MarkedMessagesReserve, dispatch: Dispatch) =>
    dispatch(actions.setArticleMarkedQuestionsReserve(data))

export const setEmptyDefaultArticle = (dispatch: Dispatch) => dispatch(actions.getDefaultArticleSuccess(false))

export const setEditedArticleExtId = (dispatch: Dispatch, newExtId = "") =>
    dispatch(actions.updateEditedArticleExtId(newExtId))

export const setEditedArticleSurvey = (dispatch: Dispatch, newSurvey: string[] = []) =>
    dispatch(actions.updateEditedArticleSurvey(newSurvey))

export const setArticleExpanded = (dispatch: Dispatch, expanded = false) =>
    dispatch(actions.setArticleExpanded(expanded))

export const returnToComparedVersion = (article: CurrentArticleState) => {
    return (dispatch: Dispatch) => {
        dispatch(actions.setArticleCompareMode(ArticleCompareMode.Default))
        dispatch(actions.setNewArticleFromState(article))
    }
}
